<template>
  <div>
    <b-overlay :show="loading">
      <filtros-teletutoria
        report-type="teletutoria"
        @buscar="buscar"
      />
      <b-card>
        <b-card-body>
          <h4 class="card-title">
            Listado Teletutorias
          </h4>
          <div>
            <b-row>

                <b-col
                        sm="4"
                        md="12"
                        class="my-1"
                >
                  <div class="text-right">
                    <b-button
                            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                            variant="outline-primary"
                            type="submit"
                            class="ml-1"
                            @click="exportar"
                    >
                      <feather-icon
                              icon="DownloadIcon"
                              class="mr-50"
                      />
                      <span class="align-middle">Exportar</span>

                    </b-button>
                  </div>
                </b-col>


            </b-row>
            <b-row>
              {{ notificaciones }}
              <b-col
                sm="4"
                md="3"
                class="my-1"
              />
              <b-col
                sm="4"
                md="2"
                class="my-1"
              >
                <div>
                  <h5 class="text-right pt-1">
                    Teletutor:
                  </h5>
                </div>
              </b-col>
              <b-col
                sm="4"
                md="4"
                class="my-1"
              >
                <div>
                  <autocomplete-infinity
                    id="teletutor"
                    url="contact-center/usuario-contact"
                    placeholder="Seleccione Teletutor Para Asignar"
                    label="nombre_contact"
                    items-per-page="10"
                    @item-selected="selectedTeletutorAsignar1"
                  />
                </div>
              </b-col>
              <b-col
                sm="4"
                md="3"
                class="my-1"
              >
                <div
                    v-b-tooltip.hover.v-primary title="Debe seleccionar tutor y alumno para habilitar"
                  class="float-right"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-modal.modal-crear
                    variant="outline-primary"
                    :disabled="bloquearAsignar"
                    @click="asignarTeletutor"
                  >
                    <feather-icon
                      icon="FilePlusIcon"
                      class="mr-50"
                    />
                    Asignar Teletutor
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                      cols="4"
                      class="my-1"
              >
                <div class="d-flex align-items-center mb-1 mb-md-0">
                  <label>Mostrar</label>
                  <b-form-select
                          id="perPageSelect"
                          v-model="perPage"
                          :options="pageOptions"
                          size="sm"
                          class="mx-50 col-md-3"
                          @change="onPerPageChange"
                  />
                  <label>filas por página</label>
                </div>
              </b-col>
              <b-col
                      cols="4"
                      class="mt-2"
              >
                <div v-if="totalRows > 0">
                  <span class="text-muted">  Mostrando del {{ from }} al {{ to }} de {{ totalRows }} filas</span>
                </div>
              </b-col>

              <b-col cols="4">
                <b-pagination
                        v-model="page"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        align="right"
                        class="mt-2"
                        aria-controls="my-table"
                        @change="onPageChange"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-table
                  striped
                  hover
                  small
                  :per-page="perPage"
                  :items="items"
                  :fields="fields"
                  responsive
                  class="mb-0"
                  show-empty
                >
                  <template #head(accion)="data">

                    <div class="text-center">

                      <b-form-checkbox
                        v-model="allSelected"
                        @change="toggleAll"
                      />
                    </div>
                  </template>
                  <template #cell(accion)="data">
                    <div class="text-center">
                      <b-form-checkbox
                        v-model="selected"
                        name="checkbox"
                        :value="data.value"
                      />
                    </div>
                  </template>
                  <template #empty="scope">
                    <div class="text-center">
                      No existen resultados
                    </div>
                  </template>
                </b-table>
              </b-col>


            </b-row>
            <b-row class="pl-1">

            </b-row>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>
    <b-modal
      ref="modalExport"
      title="Información"
      ok-only
      ok-title="Cerrar"
    >
      <p class="my-4">
        <b>Procesando el reporte</b>! Recibirá un correo con el enlace para descargar el informe
      </p>
    </b-modal>
  </div>

</template>

<script>

import { VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import es from 'vee-validate/dist/locale/es.json'
import FiltrosTeletutoria from '@/components/FiltrosTeletutoria'

export default {
  name: 'DescuentoProductos',
  components: {

    'filtros-teletutoria': FiltrosTeletutoria,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {

  },
  data() {
    return {
      perPage: 50,
      pageOptions: [50, 100, 500, 1000],
      totalRows: 1,
      currentPage: 1,
      from: 1,
      selectIdAlumno: [],
      to: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      page: 1,
      filterOn: [],
      count: 0,
      teletutoriaList: [],
      selectedTeletutorAsignar: '',
      selected: [],
      //teleturoSeleccionado: false,
      allSelected: false,
      optionTeletutores: [],
      disabled: 0,
      notificacion: '',
      update:false,
      fields: [
        // { key: 'id_detalle_alumno', label: 'Id' },
        { key: 'accion', label: 'Accion' },
        { key: 'rut_alumno', label: 'Rut' },
        { key: 'nombre_alumno', label: 'Nombre' },
        { key: 'apellido_alumno', label: 'Apellidos' },
        { key: 'num_ficha', label: 'Num Ficha' },
        { key: 'nombre_holding', label: 'Holding' },
        { key: 'empresa', label: 'Empresa' },
        { key: 'platform_name', label: 'Plataforma' },
        { key: 'fecha_inicio', label: 'Fecha Inicio' },
        { key: 'fecha_fin', label: 'Fecha Fin' },
        { key: 'modalidad', label: 'Modalidad' },
        { key: 'curso', label: 'Curso' },
        { key: 'tutor', label: 'Tutor' },
        { key: 'fecha_envio_carta', label: 'Fecha Carta Presentación' },
      ],
      filter: {
        selectedEmpresa: '',
        selectedFicha: '',
        selectedAsignado: '',
        selectedTeletutor: '',
        cursos_cerrados:0,

      },
    }
  },
  computed: {
    bloquearAsignar: function () {

      if(this.selected.length > 0 && this.selectedTeletutorAsignar != '') {
        if(this.selected.length > 0 && this.selectedTeletutorAsignar != null) {
          return false
        }
        return true
      }
      return true
    },
    notificaciones() {
      this.notificacion = this.$store.state.teletutoria.notificaciones
      if (this.notificacion.titulo !== '') {
        this.makeToast('success', this.notificacion.titulo, this.notificacion.mensaje)
        this.notificacion.titulo = ''
        this.filter.selectedAsignado = ''
        if (this.update){
          this.getListadoTeletutoria()
          this.update = false
        }

      }
    },
    // eslint-disable-next-line consistent-return,vue/return-in-computed-property
    items() {
      this.totalRows = this.$store.state.teletutoria.rowsTotal
      this.teletutoriaList = this.$store.state.teletutoria.teletutorias
     this.currentPage = this.$store.state.teletutoria.currentPage
      this.to = this.$store.state.teletutoria.to
      this.from = this.$store.state.teletutoria.from
      if (this.teletutoriaList !== undefined) {
        return this.teletutoriaList.map(item => ({
          id_detalle_alumno: item.id_detalle_alumno,
          rut_alumno: item.rut_alumno,
          nombre_alumno: item.nombre_alumno,
          apellido_alumno: item.apellido_alumno,
          num_ficha: item.num_ficha,
          nombre_holding: item.nombre_holding,
          empresa: item.empresa,
          platform_name: item.platform_name,
          fecha_inicio: item.fecha_inicio,
          fecha_fin: item.fecha_fin,
          modalidad: item.modalidad,
          curso: item.curso,
          tutor: item.contact_asignado,
          accion: item.id_detalle_alumno,
          fecha_envio_carta: item.fecha_envio_carta,
        }))
      }
    },
    loading() {
      return this.$store.state.teletutoria.loading
    },

  },
  watch: {

  },
  mounted() {
    this.getListadoTeletutoria()
  },
  methods: {
    selectedTeletutorAsignar1(teletutor) {
      this.selectedTeletutorAsignar = teletutor != null ? teletutor.id_usuario : null
    },
    exportar() {
      if (this.teletutoriaList.length == 0) {
        this.$bvToast.toast('Debe realizar una búsqueda antes de exportar', {
          title: 'ALERTA!!',
          variant: 'warning',
          solid: true,
        })
      } else {
        this.getExportData()
      }
    },
    getExportData() {
      const fieldsV = []
      Object.entries(this.fields).forEach(entry => {
        const [key, value] = entry
        if (value.display) {
          fieldsV.push(key)
        }
      })

      this.filter.fields = fieldsV
      const filterData = {
        id_empresa: this.filter.selectedEmpresa,
        id_ficha: this.filter.selectedFicha,
        id_contact: this.filter.selectedTeletutor,
        id_asignar: this.filter.selectedAsignado,
        cursos_cerrados:this.filter.cursos_cerrados==true ? 1 : 0,
        page: this.page,
        perPage: this.perPage,
      }
      if (this.totalRows >= 50000) {
        this.$refs.modalExport.show()
        this.$store.dispatch('teletutoria/exportar', filterData)
      } else {
        this.$store.dispatch('teletutoria/exportarMenor5000', filterData)
      }
    },
    asignarTeletutor() {
      const asignarData = {
        id_detalle_alumno: this.selected,
        id_usuario: this.selectedTeletutorAsignar,
      }
      this.$store.dispatch('teletutoria/AsingTeletutorFicha', asignarData)
      this.selected = []
      this.update = true
     // this.teleturoSeleccionado = false
    },
    toggleAll(checked) {
      this.selected = []
      if (checked) {
        this.teletutoriaList.map(item => (
          this.selected.push(item.id_detalle_alumno)
        ))
      } else {
        this.selected = []
      }
    },
    getListadoTeletutoria() {
      const filterData = {
        page: this.page,
        perPage: this.perPage,
        id_empresa: this.filter.selectedEmpresa,
        id_ficha: this.filter.selectedFicha,
        id_contact: this.filter.selectedTeletutor,
        id_asignar: this.filter.selectedAsignado,
        cursos_cerrados:this.filter.cursos_cerrados==true ? 1 : 0,
      }
      this.$store.dispatch('teletutoria/listadoTeletutoria', filterData)
    },
    buscar(filter) {
      filter.selectedEmpresa == null ? this.filter.selectedEmpresa = '' : this.filter.selectedEmpresa = filter.selectedEmpresa
      filter.selectedFicha == null ? this.filter.selectedFicha = '' : this.filter.selectedFicha = filter.selectedFicha
      filter.selectedTeletutor == null ? this.filter.selectedTeletutor = '' : this.filter.selectedTeletutor = filter.selectedTeletutor
      filter.selectedAsignado == null ? this.filter.selectedAsignado = '' : this.filter.selectedAsignado = filter.selectedAsignado.id
      this.page = 1
      this.filter.cursos_cerrados=filter.cursos_cerrados==true ? 1 : 0;
      this.getListadoTeletutoria()
    },
    onPageChange(page) {
      this.page = page
      this.getListadoTeletutoria()
    },
    onPerPageChange(perPage) {
      this.perPage = perPage
      this.getListadoTeletutoria()
    },
    makeToast(variant = null, titulo, mensaje) {
      this.$bvToast.toast(mensaje, {
        // title: `Variant ${variant || 'default'}`,
        title: titulo,
        variant,
        solid: true,
      })
    },
  },
}
</script>

<style>

</style>
